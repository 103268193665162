<script>
import { ANNOUNCEMENTS } from "@/constants/announcement";
import AnnouncementCard from "../../components/homepage/AnnouncementCard.vue";
import Banner from "../../components/homepage/Banner.vue";
export default {
  components: { AnnouncementCard, Banner },
  data: () => ({
    announcements: ANNOUNCEMENTS,
    breadcrumbs: [
      {
        label: "Beranda",
        url: "/",
      },
      {
        label: "Pengumuman",
        url: "",
      },
    ],
  }),
};
</script>
<template>
  <div>
    <banner
      :breadcrumbs="breadcrumbs"
      title="Pengumuman"
      :icon="require('@/assets/home/decoration/announcement.svg')"
    />
    <div class="row mx-4">
      <div
        class="col-md-4 col-lg-3 p-3"
        v-for="(announcement, idx) in announcements"
        :key="idx"
      >
        <announcement-card class="h-100" :announcement="announcement" />
      </div>
    </div>
  </div>
</template>
