<script>
export default {
  props: {
    vertical: Boolean,
    announcement: Object,
  },
};
</script>
<template>
  <div class="announcement-card shadow-sm" :class="{ vertical }">
    <div class="announcement-card--image">
      <img
        :src="require(`@/assets/announcement/${announcement.img}`)"
        :alt="announcement.title"
      />
    </div>
    <div class="announcement-card__content">
      <h5 class="announcement-card--title">
        {{ announcement.title }}
      </h5>
      <p class="text-muted">
        {{ announcement.description }}
      </p>
      <a
        class="announcement-card--cta"
        :href="announcement.url"
        target="_blank"
        rel="noreferrer"
      >
        baca selengkapnya <i class="fas fa-chevron-right" />
      </a>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.announcement-card {
  background: white;
  padding: 1rem;
  border-radius: 0.75rem;
  gap: 1rem;
  display: flex;
  flex-direction: column;
  &.vertical {
    flex-direction: row;
  }
  &--image {
    overflow: hidden;
    border-radius: 0.5rem;
    max-height: 400px;
    & img {
      transition: 0.3s all ease-in;
      width: 100%;
      height: 100%;
      object-fit: cover;
      aspect-ratio: 1/1;
      &:hover {
        transform: scale(110%);
      }
    }
  }
  &--title {
    font-size: 24px !important;
    font-weight: 700;
  }

  &--cta {
    color: var(--primary-500);

    display: block;
  }

  &__content {
    & > * {
      margin-bottom: 0.5rem;
    }
  }
}
</style>
